import { useTranslation } from 'react-i18next'

import styles from './CoverSettingAlertContent.module.scss'
import TeacherAvailabilityText from './TeacherAvailabilityText'
import type { DetailedTeacherAvailability } from '../types'

type CoverSettingAlertContentProps = {
  isPastLesson: boolean
  isCoveredLesson: boolean
  isCancelledLesson: boolean
  isBothTeacherUnavailable: boolean
  teacherAvailability?: DetailedTeacherAvailability
  coTeacherAvailability?: DetailedTeacherAvailability
}

const CoverSettingAlertContent = (props: CoverSettingAlertContentProps) => {
  const { t } = useTranslation('lessonDetails')

  return (
    <div className={styles.container}>
      {props.isCoveredLesson ? (
        <span>{t('help.lesson-already-set-as-cover')}</span>
      ) : null}

      {props.isCancelledLesson ? (
        <span>{t('help.lesson-is-already-cancelled')}</span>
      ) : null}

      {props.isPastLesson ? (
        <span>{t('help.lesson-has-been-completed')}</span>
      ) : null}

      {props.isBothTeacherUnavailable ? (
        <span>
          {t('help.both-teachers-unavailable', {
            TEACHER: props.teacherAvailability?.name,
            CO_TEACHER: props.coTeacherAvailability?.name
          })}
        </span>
      ) : (
        <>
          <TeacherAvailabilityText
            teacherAvailability={props.teacherAvailability}
          />
          <TeacherAvailabilityText
            teacherAvailability={props.coTeacherAvailability}
          />
        </>
      )}
    </div>
  )
}

export default CoverSettingAlertContent
