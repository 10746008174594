import useTeacherAvailability from '../queries/useTeacherAvailability'
import { type TeacherAvailability, type Lesson } from '../types'

type TeacherCoverAvailabilityProps = {
  lesson: Lesson
  isPastLesson: boolean
  coverTeacher?: string
  coverCoTeacher?: string
  teacherList: TeacherAvailability[]
}

const useTeacherCoverAvailability = (props: TeacherCoverAvailabilityProps) => {
  const teacherOption = props.teacherList.find(
    teacherItem => teacherItem.id === props.coverTeacher
  )

  const coTeacherOption = props.teacherList.find(
    coTeacherItem => coTeacherItem.id === props.coverCoTeacher
  )

  const isAnyWarningExist =
    props.lesson.isCancelled ||
    props.lesson.isCovered ||
    props.isPastLesson ||
    (teacherOption && teacherOption?.status !== 'available') ||
    (coTeacherOption && coTeacherOption?.status !== 'available')

  const { data: teacherAvailability } = useTeacherAvailability({
    startDate: props.lesson.startDate,
    endDate: props.lesson.endDate,
    teacherId: props.coverTeacher,
    ignoreLessonId: [props.lesson.id],
    enabled: !!props.coverTeacher && teacherOption?.status !== 'available'
  })

  const { data: coTeacherAvailability } = useTeacherAvailability({
    startDate: props.lesson.startDate,
    endDate: props.lesson.endDate,
    teacherId: props.coverCoTeacher,
    ignoreLessonId: [props.lesson.id],
    enabled: !!props.coverCoTeacher && coTeacherOption?.status !== 'available'
  })

  const isBothTeacherUnavailable =
    teacherAvailability?.status === 'unavailable' &&
    coTeacherAvailability?.status === 'unavailable'

  return {
    isAnyWarningExist,
    isBothTeacherUnavailable,
    teacherAvailability,
    coTeacherAvailability
  }
}

export default useTeacherCoverAvailability
