import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    full_name: z.string().min(1)
  })
)

type Teacher = {
  id: string
  fullName: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Teacher[] =>
  response.map(item => ({
    id: item.id,
    fullName: item.full_name
  }))

const useTeachers = ({ onlyTutor }: { onlyTutor?: boolean } = {}) =>
  useQuery({
    queryKey: ['panelTeachersList', onlyTutor],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelTeachersList({
        is_tutor: onlyTutor,
        page_size: 'max',
        fetchKeys: {
          id: true,
          full_name: true
        }
      })
      const parsedTeachers = parseResponse(
        responseSchema.parse(response.results)
      )

      const options = parsedTeachers.map(teacher => ({
        label: teacher.fullName,
        value: teacher.id
      }))

      return {
        options
      }
    }
  })

export default useTeachers
