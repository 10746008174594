import { useTranslation } from 'react-i18next'

import styles from './NotApplicable.module.scss'

const NotApplicable = () => {
  const { t } = useTranslation(['common'])

  return (
    <span className={styles.notApplicable}>{t('text.not-applicable')}</span>
  )
}
export default NotApplicable
