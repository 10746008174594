import { createFileRoute, Outlet } from '@tanstack/react-router'
import { z } from 'zod'

import { CourseView, lessonListQueryOptions } from '@/modules/lessonDetails'
import { courseQueryOptions } from '@/queries/useCourse'

export const Route = createFileRoute('/_auth/timetable/$courseId/$groupId')({
  component: () => (
    <CourseView>
      <Outlet />
    </CourseView>
  ),
  validateSearch: z.object({
    lessonId: z.string().optional()
  }),
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ params, context, navigate, deps: { search } }) => {
    const course = await context.queryClient.ensureQueryData(
      courseQueryOptions(params.courseId)
    )

    const lessonList = await context.queryClient.ensureQueryData(
      lessonListQueryOptions(params.groupId)
    )

    if (
      location.pathname === `/timetable/${params.courseId}/${params.groupId}`
    ) {
      navigate({
        to: '/timetable/$courseId/$groupId/lesson-details',
        params
      })
    }

    if (!search.lessonId) {
      navigate({
        replace: true,
        search: { lessonId: lessonList.closestLesson.id, ...search },
        params: { courseId: params.courseId, groupId: params.groupId }
      })
    }
    return course.name
  },
  meta: ({ loaderData }) => [{ title: loaderData }]
})
