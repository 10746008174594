import { createFileRoute } from '@tanstack/react-router'

import i18n from '@/i18n'
import { abscencesFilterSchema, AbsencesListView } from '@/modules/absences'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const Route = createFileRoute('/_auth/absences')({
  validateSearch: abscencesFilterSchema,
  component: () => <AbsencesListView />,
  meta: () => [{ title: i18n.t('navigation.absences', { ns: 'common' }) }],
  loaderDeps: ({ search }) => ({
    ...search
  }),
  loader: async ({ deps: search, context, navigate }) => {
    const semesters =
      await context.queryClient.ensureQueryData(semesterQueryOptions)

    if (!search.semester) {
      navigate({
        to: '/absences',
        replace: true,
        search: { ...search, semester: semesters.currentSemester?.id }
      })
    }
  }
})
