import { useRouterState, type LinkProps } from '@tanstack/react-router'
import React from 'react'

import Breadcrumbs, {
  type BreadcrumbItem
} from '@/components/Breadcrumbs/Breadcrumbs'
import Tabs, { type Route } from '@/components/Tabs/Tabs'
import Header from '@/components/common/Header/Header'
import type { routeTree } from '@/routeTree.gen'

import Actions, { type Action } from './Actions'
import styles from './BasicLayout.module.scss'

export type HeaderAction = Action

type BasicLayoutProps = {
  routes?: Route[]
  actions?: HeaderAction[]
  moduleName?: string
  header: React.ReactNode
  children: React.ReactNode
}

const BasicLayout = (props: BasicLayoutProps) => {
  const breadcrumbs: BreadcrumbItem[] = useRouterState({
    select: state =>
      state.matches
        .map(match => ({
          title:
            match.meta?.find(metaElement => metaElement.title)?.title || '',
          to: match.pathname as LinkProps<typeof routeTree>['to'],
          params: match.params
        }))
        .filter(breadcrumb => breadcrumb.to !== '/' && breadcrumb.title)
  })

  return (
    <div className={styles.page}>
      <Header
        navbar={
          <div className={styles.navbar}>
            <Breadcrumbs items={breadcrumbs} className={styles.breadcrumbs} />
            <span className={styles.moduleName}>{props.moduleName}</span>
          </div>
        }
        tabs={props.routes ? <Tabs routes={props.routes} /> : null}
      >
        <div className={styles.headerWrapper}>
          {typeof props.header === 'string' ? (
            <h1 className={styles.header}>{props.header}</h1>
          ) : (
            props.header
          )}

          <Actions actions={props.actions?.filter(action => !action.hidden)} />
        </div>
      </Header>

      <main className={styles.content}>{props.children}</main>
    </div>
  )
}

export default BasicLayout
