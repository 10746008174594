import { zodResolver } from '@hookform/resolvers/zod'
import { isPast } from 'date-fns'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import WarningCircle from '@/assets/icons/warning-circle.svg?react'
import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'
import FormField from '@/components/FormField'
import ModalForm from '@/components/Modal/ModalForm'
import DirtyModal from '@/components/common/DirtyModal'
import { toast } from '@/hooks/useToast'

import CoverSettingAlertContent from './CoverSettingAlertContent'
import styles from './CoverSettingModal.module.scss'
import CoverTeacherField from './CoverTeacherField'
import {
  formSchemaCoverTeacher,
  type CoverTeacherFormSchema
} from '../constants/cover-payload'
import useTeacherCoverAvailability from '../hooks/useTeacherCoverAvailability'
import useSetCover from '../mutations/useSetCover'
import useTeachersAvailability from '../queries/useTeachersAvailability'
import type { Lesson } from '../types'
import {
  getLessonFormattedTime,
  getLessonShortedDetails
} from '../utils/lesson-details-format'

type CoverSettingModalProps = {
  courseName: string
  groupName: string
  lesson: Lesson
  open: boolean
  onOpenChange: (open: boolean) => void
  onCoverChange: () => void
}

const CoverSettingModal = (props: CoverSettingModalProps) => {
  const { t } = useTranslation('lessonDetails')

  const isPastLesson = !!props.lesson.endDate
    ? isPast(props.lesson.endDate)
    : false

  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState(false)
  const [isCoverLessonModalOpen, setIsCoverLessonModalOpen] = useState(false)

  const { mutate: setCover, isPending: isCoverLoading } = useSetCover(
    props.lesson.id,
    {
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.cover-created-successfully', {
            LESSON: getLessonFormattedTime(props.lesson)
          })
        })
        props.onCoverChange()
        handleClose()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.cover-created-failed', {
            LESSON: getLessonFormattedTime(props.lesson)
          })
        })
        handleClose()
      }
    }
  )

  const { teacher, coTeacher } = props.lesson

  const handleOnCancel = () => {
    isDirty ? setIsDirtyModalOpen(true) : handleClose()
  }

  const form = useForm<CoverTeacherFormSchema>({
    resolver: zodResolver(formSchemaCoverTeacher()),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      teacher: null,
      coTeacher: null
    }
  })

  useEffect(() => {
    if (props.open) {
      form.setValue('teacher', props.lesson.teacherCover?.id)
      form.setValue('coTeacher', props.lesson.coTeacherCover?.id)
    }
  }, [form, props.lesson, props.open])

  const { isDirty } = form.formState

  const { data: teachers, isPending: isTeacherOptionsLoading } =
    useTeachersAvailability({
      startDate: props.lesson.startDate,
      endDate: props.lesson.endDate,
      enabled: props.open,
      ignoreLessonId: [props.lesson.id]
    })

  const handleSubmit = () => {
    if (isAnyWarningExist) setIsCoverLessonModalOpen(true)
    else setCover(form.getValues())
  }

  const handleClose = () => {
    props.onOpenChange(false)
    form.reset()
    setIsCoverLessonModalOpen(false)
  }

  const coverTeacher = form.watch('teacher') || undefined
  const coverCoTeacher = form.watch('coTeacher') || undefined

  const {
    isAnyWarningExist,
    isBothTeacherUnavailable,
    teacherAvailability,
    coTeacherAvailability
  } = useTeacherCoverAvailability({
    lesson: props.lesson,
    teacherList: teachers?.list || [],
    coverTeacher,
    coverCoTeacher,
    isPastLesson
  })

  return (
    <ModalForm
      id="cover-lesson"
      title={t('header.cover-lesson', { COURSE: props.courseName })}
      description={getLessonShortedDetails(props.lesson)}
      submitText={t('button.cover')}
      form={form}
      size="md"
      loading={isCoverLoading}
      open={props.open}
      onOpenChange={props.onOpenChange}
      onSubmit={handleSubmit}
      onCancel={handleOnCancel}
      onClose={handleOnCancel}
    >
      <h4 className={styles.label}>{t('header.cover-for')}</h4>
      <div className={styles.fieldContainer}>
        <span className={styles.teacherlabel}>{t('header.main-teacher')}</span>
        <FormField
          label={t('label.select-teacher')}
          control={form.control}
          id="teacher"
          name="teacher"
          render={({ inputProps }) => (
            <CoverTeacherField
              placeholder={t('help.select-teacher')}
              teacher={teacher}
              onClear={() => form.resetField('teacher')}
              inputProps={{
                ...inputProps,
                value: inputProps.value || undefined,
                onChange: value => {
                  inputProps.onChange?.(value)
                  form.trigger()
                }
              }}
              teacherList={teachers?.list || []}
              options={teachers?.options || []}
              isOptionsLoading={isTeacherOptionsLoading}
            />
          )}
        />
      </div>
      {coTeacher ? (
        <div className={styles.fieldContainer}>
          <span className={styles.teacherlabel}>{t('header.co-teacher')}</span>
          <FormField
            label={t('label.select-co-teacher')}
            control={form.control}
            id="coTeacher"
            name="coTeacher"
            render={({ inputProps }) => (
              <CoverTeacherField
                placeholder={t('help.select-co-teacher')}
                teacher={coTeacher}
                onClear={() => form.resetField('coTeacher')}
                inputProps={{
                  ...inputProps,
                  value: inputProps.value || undefined,
                  onChange: value => {
                    inputProps.onChange?.(value)
                    form.trigger()
                  }
                }}
                teacherList={teachers?.list || []}
                options={teachers?.options || []}
                isOptionsLoading={isTeacherOptionsLoading}
              />
            )}
          />
        </div>
      ) : null}

      {form.formState.errors.teacher && form.formState.errors.coTeacher ? (
        <div className={styles.errorContainer}>
          <WarningCircle className={styles.errorIcon} />
          {t('error.one-field-is-required')}
        </div>
      ) : null}

      <div className={styles.alertsWrapper}>
        {isAnyWarningExist ? (
          <Alert
            variant="warning"
            message={
              <CoverSettingAlertContent
                isPastLesson={isPastLesson}
                isCoveredLesson={props.lesson.isCovered}
                isCancelledLesson={props.lesson.isCancelled}
                isBothTeacherUnavailable={isBothTeacherUnavailable}
                teacherAvailability={teacherAvailability}
                coTeacherAvailability={coTeacherAvailability}
              />
            }
          />
        ) : null}
        <Alert variant="info" message={t('help.cover-only-current-lesson')} />
      </div>

      <ConfirmModal
        id="cover-lesson-confirm"
        header={t('header.cover-lesson-confirm', {
          COURSE: props.lesson.course.name
        })}
        description={props.lesson ? getLessonShortedDetails(props.lesson) : ''}
        open={isCoverLessonModalOpen}
        onOpenChange={setIsCoverLessonModalOpen}
        alertText={
          <CoverSettingAlertContent
            isPastLesson={isPastLesson}
            isCoveredLesson={props.lesson.isCovered}
            isCancelledLesson={props.lesson.isCancelled}
            isBothTeacherUnavailable={isBothTeacherUnavailable}
            teacherAvailability={teacherAvailability}
            coTeacherAvailability={coTeacherAvailability}
          />
        }
        subheader={
          <div className={styles.coverLessonConfirmContainer}>
            <span>{t('help.would-you-like-set-cover')}</span>
            <span>{t('help.this-will-cause-conflict')}</span>
          </div>
        }
        confirmButton={
          <Button
            loading={isCoverLoading}
            variant="danger"
            onClick={() => setCover(form.getValues())}
          >
            {t('button.confirm')}
          </Button>
        }
      />

      <DirtyModal
        isOpen={isDirtyModalOpen}
        onOpenChange={setIsDirtyModalOpen}
        onConfirm={() => {
          handleClose()
          setIsDirtyModalOpen(false)
        }}
      />
    </ModalForm>
  )
}

export default CoverSettingModal
