import clsx from 'clsx'

import LeftIcon from '@/assets/icons/chevron-small-left.svg?react'
import RightIcon from '@/assets/icons/chevron-small-right.svg?react'

import styles from './SwitchLeftRight.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

type SwitchLeftRightProps = {
  className?: string
  children: React.ReactNode
  additionalContent?: React.ReactNode
  left: {
    disabled?: boolean
    onClick: () => void
  }
  right: {
    disabled?: boolean
    onClick: () => void
  }
}

const SwitchLeftRight = (props: SwitchLeftRightProps) => (
  <div className={clsx(styles.wrapper, props.className)}>
    <ButtonIcon
      variant="primary"
      size="medium"
      className={styles.arrowIcon}
      disabled={props.left.disabled}
      onClick={props.left.onClick}
      dataTestId="left-arrow"
    >
      <LeftIcon />
    </ButtonIcon>

    <span className={styles.content}>{props.children}</span>

    <ButtonIcon
      variant="primary"
      size="medium"
      className={styles.arrowIcon}
      disabled={props.right.disabled}
      onClick={props.right.onClick}
      dataTestId="right-arrow"
    >
      <RightIcon />
    </ButtonIcon>

    {props.additionalContent ? (
      <span className={styles.additionalContent}>
        {props.additionalContent}
      </span>
    ) : null}
  </div>
)

export default SwitchLeftRight
