import i18n from '@/i18n'
import { formatDateTime, formatTime } from '@/utils/format-date'

import type { Lesson } from '../types'

export const getLessonName = ({ lessonNumber }: { lessonNumber?: number }) =>
  `${i18n.t('label.lesson-number', { ns: 'lessonDetails', NUMBER: lessonNumber || '' })}`

export const getLessonFormattedTime = (lesson: Lesson) =>
  `${formatDateTime(lesson?.startDate)} - ${formatTime(lesson?.endDate)}`

export const getLessonShortedDetails = (lesson: Lesson) =>
  `${lesson.teacher.name}, ${lesson.group.name}, ${getLessonFormattedTime(lesson)}`
