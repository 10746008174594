import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import { isBefore } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CalendarIcon from '@/assets/icons/calendar.svg?react'
import { DATE_PLACEHOLDER } from '@/constants/date-format'
import { MAX_DATE } from '@/constants/max-date'
import type { FormFieldType } from '@/types/form-field-type'
import { formatDate } from '@/utils/format-date'
import { parseDate } from '@/utils/parse-date'

import styles from './DateRangePicker.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Calendar, {
  type DateRange as DateRangeCalendar
} from '../Calendar/Calendar'
import Input from '../Input/Input'
import Label from '../Label'

export type DateRange = Partial<DateRangeCalendar>

export type DatePickerProps = FormFieldType<DateRange | undefined> & {
  disabled?: boolean
}

const DateRangePicker = (props: DatePickerProps) => {
  const { t } = useTranslation(['common'])

  const [dateFromInputValue, setDateFromInputValue] = useState(
    formatDate(props.value?.from)
  )

  const [dateToInputValue, setDateToInputValue] = useState(
    formatDate(props.value?.to)
  )

  const handleOnDateChange = (dateValue?: DateRangeCalendar) => {
    setDateFromInputValue(formatDate(dateValue?.from))
    setDateToInputValue(formatDate(dateValue?.to))
    props.onChange?.(dateValue)
  }

  const handleOnDateInputFromBlur = (value: string) => {
    if (!value) {
      setDateToInputValue('')
      props.onChange?.(undefined)
      return
    }

    const newFromDate = parseDate(value)
    const toDate = props.value?.to

    if (newFromDate && isBefore(newFromDate, MAX_DATE)) {
      if (toDate && isBefore(newFromDate, toDate))
        props.onChange?.({ from: newFromDate, to: toDate })
      else props.onChange?.({ from: newFromDate })
    } else {
      setDateFromInputValue(formatDate(props.value?.from))
      props.onChange?.(props.value)
    }
  }

  const handleOnDateInputToBlur = (value: string) => {
    if (!value) {
      setDateToInputValue('')
      props.onChange?.({ ...props.value, to: undefined })
      return
    }

    const newToDate = parseDate(value)
    const fromDate = props.value?.from

    const areDatesValid = !!newToDate && !!fromDate

    if (
      areDatesValid &&
      isBefore(newToDate, MAX_DATE) &&
      isBefore(fromDate, newToDate)
    ) {
      if (props.value?.from) props.onChange?.({ from: fromDate, to: newToDate })
    } else {
      setDateToInputValue(formatDate(props.value?.to))
      props.onChange?.(props.value)
    }
  }

  return (
    <Popover.Root>
      <div
        className={clsx(
          styles.inputContainer,
          props.invalid && styles.invalid,
          props.disabled && styles.disabled
        )}
      >
        <Popover.Anchor>
          <div className={styles.inputRangeContainer} id={props.id}>
            <Label
              id={`${props.id}-from`}
              hidden
              label={t('label.from')}
              className={styles.inputFrom}
            >
              <Input
                borderless
                required={props.required}
                id={`${props.id}-from`}
                disabled={props.disabled}
                value={dateFromInputValue}
                onBlur={handleOnDateInputFromBlur}
                onChange={setDateFromInputValue}
                describedby={props.describedby}
                invalid={props.invalid}
                placeholder={DATE_PLACEHOLDER}
              />
            </Label>
            <span className={styles.divider}>-</span>
            <Label
              id={`${props.id}-to`}
              hidden
              label={t('label.to')}
              className={styles.inputTo}
            >
              <Input
                borderless
                id={`${props.id}-to`}
                required={props.required}
                describedby={props.describedby}
                disabled={props.disabled}
                invalid={props.invalid}
                value={dateToInputValue}
                onBlur={handleOnDateInputToBlur}
                onChange={setDateToInputValue}
                placeholder={DATE_PLACEHOLDER}
              />
            </Label>
          </div>
        </Popover.Anchor>
        <Popover.Trigger asChild>
          <ButtonIcon
            disabled={props.disabled}
            size="small"
            variant="tertiary"
            className={styles.calendarButton}
          >
            <CalendarIcon />
          </ButtonIcon>
        </Popover.Trigger>
      </div>
      <Popover.Portal>
        <Popover.Content className={styles.calendarPopover}>
          <Calendar
            id={props.id}
            mode="range"
            value={
              props.value?.from
                ? { from: props.value.from, to: props.value.to }
                : undefined
            }
            onChange={handleOnDateChange}
            initialFocus
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default DateRangePicker
