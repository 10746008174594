import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import deAbsences from './modules/absences/translations/de.json'
import enAbsences from './modules/absences/translations/en.json'
import deAuth from './modules/auth/translations/de.json'
import enAuth from './modules/auth/translations/en.json'
import deClasses from './modules/classes/translations/de.json'
import enClasses from './modules/classes/translations/en.json'
import deCourses from './modules/courses/translations/de.json'
import enCourses from './modules/courses/translations/en.json'
import deLessonDetails from './modules/lessonDetails/translations/de.json'
import enLessonDetails from './modules/lessonDetails/translations/en.json'
import deStudents from './modules/students/translations/de.json'
import enStudents from './modules/students/translations/en.json'
import deTimetable from './modules/timetable/translations/de.json'
import enTimetable from './modules/timetable/translations/en.json'
import deUsers from './modules/users/translations/de.json'
import enUsers from './modules/users/translations/en.json'
import deCommon from './translations/de.json'
import enCommon from './translations/en.json'

export const resources = {
  en: {
    common: enCommon,
    auth: enAuth,
    timetable: enTimetable,
    lessonDetails: enLessonDetails,
    students: enStudents,
    classes: enClasses,
    courses: enCourses,
    users: enUsers,
    absences: enAbsences
  },
  de: {
    common: deCommon,
    auth: deAuth,
    timetable: deTimetable,
    courses: deCourses,
    lessonDetails: deLessonDetails,
    students: deStudents,
    classes: deClasses,
    users: deUsers,
    absences: deAbsences
  }
} as const

i18n.use(initReactI18next).init({
  preload: ['en'],
  resources,
  lng:
    localStorage.getItem('language') || import.meta.env.VITE_DEFAULT_LANGUAGE,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
})

export default i18n
