import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import styles from './TextWithLabel.module.scss'
import Tooltip from '../Tooltip/Tooltip'

export type TextWithLabelSize = 'small' | 'large'

type TextWithLabelProps = {
  label?: string
  text?: string | boolean | string[] | React.ReactNode
  variant?: 'horizontal' | 'vertical' | 'horizontal-full-row'
  textVariant?: 'normal' | 'grayed-out'
  size?: TextWithLabelSize
  dataTestId?: string
  tooltip?: string
}

const defaultProps = {
  variant: 'vertical',
  size: 'large',
  textVariant: 'normal'
} satisfies Partial<TextWithLabelProps>

const TextWithLabel = (props: TextWithLabelProps) => {
  const { t } = useTranslation(['common'])

  const propsWithDefaults = { ...defaultProps, ...props }

  const { variant, size, textVariant } = propsWithDefaults

  const classNameContainer = clsx(
    styles.container,
    styles[variant],
    props.size === 'large' && styles.itemLarge
  )

  const classNameLabel = clsx(
    styles.label,
    size === 'small' && styles.smallLabel,
    size === 'large' && styles.largeLabel,
    textVariant === 'grayed-out' && styles.labelGrayedOut
  )

  const classNameText = clsx(
    styles.text,
    size === 'small' && styles.smallText,
    styles[textVariant]
  )

  const parsedValue = () => {
    if (typeof props.text === 'boolean')
      return props.text ? (
        <span className={styles.success}>{t('text.yes')}</span>
      ) : (
        <span className={styles.error}>{t('text.no')}</span>
      )

    if (Array.isArray(props.text)) return props.text.join(', ') || '—'

    return props.text || '—'
  }

  return (
    <div className={classNameContainer} data-test-id={props.dataTestId}>
      {props.label ? (
        <span className={classNameLabel}>{props.label}:</span>
      ) : null}

      <span className={classNameText}>
        {!!props.tooltip ? (
          <Tooltip text={props.tooltip} trigger={parsedValue()} />
        ) : (
          parsedValue()
        )}
      </span>
    </div>
  )
}

export default TextWithLabel
