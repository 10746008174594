import clsx from 'clsx'
import { default as RcTable } from 'rc-table'
import type { ColumnType } from 'rc-table/lib/interface'
import React, { useRef, type HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import ChevronSmallDown from '@/assets/icons/chevron-small-down.svg?react'
import ChevronSmallUp from '@/assets/icons/chevron-small-up.svg?react'
import MoreOutlined from '@/assets/icons/menu-vertical.svg?react'
import TriangleDown from '@/assets/icons/triangle-down.svg?react'
import TriangleUp from '@/assets/icons/triangle-up.svg?react'
import useStickyElement, { PRIORITY } from '@/hooks/useStickyElement'

import styles from './Table.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Checkbox from '../Checkbox/Checkbox'
import Divider from '../Divider/Divider'
import Dropdown, { DropdownMenuItem } from '../Dropdown/Dropdown'
import Label from '../Label'
import Pagination from '../Pagination/Pagination'
import Select from '../Select/Select'
import Spinner from '../Spinner'

const selectOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 }
]

export type Column<TData> = Omit<ColumnType<TData>, 'key'> & {
  sorterKey?: string
  key: string
  isHighlighted?: boolean
  filled?: boolean
  cellDataTestId?: string
}

type Action<TData> = { text: React.ReactNode; onClick: (item: TData) => void }

export type Sorting = {
  key: string
  order: 'asc' | 'desc'
} | null

type TableProps<TData extends Record<string, unknown>> = {
  data: TData[]
  id: string
  pagination?: {
    page: number
    pageSize: number
    count?: number
  }
  tableClassName?: string
  sorting?: Sorting
  actions?: Action<TData>[] | ((row: TData) => Action<TData>[])
  onRowClick?: (row: TData) => void
  onChangePage?: (page: number) => void
  onChangeSorting?: (sorting: Sorting) => void
  onChangePageSize?: (pageSize: number) => void
  columns: Column<TData>[]
  tableHeight?: string | number
  tableWidth?: string | number
  topBar?: React.ReactNode
  isLoading?: boolean
  expandedRowRender?: (record: TData) => React.ReactNode
  rowSelection?: {
    count: number
    selectedItems: string[]
    allCheckboxValue: boolean | 'mixed'
    labelHeader: string
    labelItem: (row: TData) => string
    onSelect: (value: boolean, rowId: string) => void
    onSelectAll: (value: boolean) => void
  }
  withBorders?: boolean
  noRowHoverEffect?: boolean
}

const Table = <TData extends Record<string, unknown> & { id: string }>(
  props: TableProps<TData>
) => {
  const refTable = useRef<HTMLDivElement>(null)
  const refTopBar = useRef<HTMLDivElement>(null)

  const { top: topBarTop } = useStickyElement(refTopBar, PRIORITY.tableTopBar)
  const { top: topHeader } = useStickyElement(refTable, PRIORITY.tableHeader)

  const header = refTable.current?.querySelector(
    '.rc-table-header'
  ) as HTMLTableElement
  const body = refTable.current?.querySelector(
    '.rc-table-body'
  ) as HTMLTableElement

  const { t } = useTranslation(['common'])

  const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([])

  const changeSorting = (column: Column<TData>) => {
    const isCurrentColumnSorted = props.sorting?.key === column.key

    const order = isCurrentColumnSorted
      ? currentOrder === 'asc'
        ? 'desc'
        : undefined
      : 'asc'

    if (!order) {
      props.onChangeSorting?.(null)
    }

    if (column.key && order) {
      props.onChangeSorting?.({
        key: column.key,
        order
      })
    }
  }

  const parsedColumns: Column<TData>[] = props.columns.map(column => {
    let parsedColumn = column

    if (column.sorterKey)
      parsedColumn = {
        ...parsedColumn,
        title: (
          <Sorter columnKey={column.key} sorting={props.sorting || null}>
            {column.title}
          </Sorter>
        ),
        onHeaderCell: () => ({
          onClick: () => changeSorting(column),
          role: 'button',
          ['aria-sort']:
            props.sorting?.key === column.key
              ? props.sorting?.order === 'desc'
                ? 'descending'
                : 'ascending'
              : 'none'
        })
      }
    if (column.isHighlighted)
      parsedColumn = {
        ...parsedColumn,
        className: clsx(parsedColumn.className, styles.highlightedCell)
      }

    if (!column.filled)
      parsedColumn = {
        ...parsedColumn,
        className: clsx(parsedColumn.className, styles.withPadding)
      }
    if (column.cellDataTestId)
      parsedColumn = {
        ...parsedColumn,
        onCell: () =>
          // data test id is not defined in html attributes
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ({
            ['data-test-id']: column.cellDataTestId
          }) as HTMLAttributes<HTMLTableCellElement>
      }

    return parsedColumn
  })

  const actionColumn: Column<TData> = {
    title: '',
    key: 'actions',
    width: 50,
    render: (_, record) => {
      const actions =
        typeof props.actions === 'function'
          ? props.actions?.(record)
          : props.actions || []

      return actions.length ? (
        <ActionCell actions={actions} record={record} />
      ) : undefined
    }
  }

  const isSelectable = props.rowSelection

  const isFirstColumnFixed = props.columns[0].fixed

  const checkboxColumn: Column<TData> = {
    title: (
      <Checkbox
        id="select-all"
        variant="dark"
        label={isSelectable?.labelHeader}
        hideLabel
        checked={isSelectable?.allCheckboxValue}
        value={
          isSelectable?.selectedItems.length === isSelectable?.count &&
          !!isSelectable?.selectedItems.length
        }
        onChange={value => {
          isSelectable?.onSelectAll(value)
        }}
      />
    ),
    key: 'id',
    width: 50,
    render: row => (
      <Checkbox
        id={row.id}
        hideLabel
        label={isSelectable?.labelItem(row)}
        value={isSelectable?.selectedItems.includes(row.id)}
        onChange={value => {
          isSelectable?.onSelect(value, row.id)
        }}
      />
    )
  }

  const columnsWithActions = props.actions
    ? [...parsedColumns, actionColumn]
    : parsedColumns

  const columns = !!isSelectable
    ? [checkboxColumn, ...columnsWithActions]
    : columnsWithActions

  const currentOrder = props.sorting?.order

  const handleOnPageSize = (value?: number) => {
    if (!value) return

    props.onChangePageSize?.(value)
    props.onChangePage?.(1)
  }

  const handleOnTab = (event: React.KeyboardEvent<HTMLTableSectionElement>) => {
    if (event.key === 'Tab') {
      // Scrolls table body while scrolling the header.
      // It fixes the issue of columns misaligning with headers when navigating through the header.
      body?.scroll({
        left: header?.scrollLeft
      })
    }
  }

  const components = {
    header: {
      wrapper: (headerProps: HTMLAttributes<HTMLTableSectionElement>) => (
        <thead
          {...headerProps}
          onKeyUp={handleOnTab}
          className={clsx(headerProps.className, styles.tableHead)}
        />
      )
    },
    body: {
      cell: (cellProps: HTMLAttributes<HTMLTableCellElement>) => (
        <td
          {...cellProps}
          className={clsx(cellProps.className, styles.tableCell)}
        />
      )
    }
  }

  return (
    <div>
      {props.topBar ? (
        <>
          <Divider variant="light" />
          <div
            ref={refTopBar}
            className={styles.topBar}
            style={{
              top: topBarTop
            }}
          >
            <div className={styles.topBarContent}>
              <span />

              {props.topBar}
            </div>
          </div>
        </>
      ) : null}

      <div
        className={clsx(
          styles.table,
          props.withBorders && styles.tableWithBorders,
          isFirstColumnFixed && styles.withFixedColumn,
          !props.noRowHoverEffect && styles.withHoverableRow
        )}
        ref={refTable}
        style={
          {
            '--first-column-width': isFirstColumnFixed
              ? `${props.columns[0].width}px`
              : '0px',
            '--header-position': `${topHeader}px`
          } as React.CSSProperties
        }
      >
        <RcTable
          id={props.id}
          columns={columns}
          data={props.data}
          className={props.tableClassName}
          rowClassName={row =>
            clsx(
              isSelectable?.selectedItems.includes(row.id) && styles.selectedRow
            )
          }
          expandable={
            props.expandedRowRender
              ? {
                  expandIcon: expandProps => (
                    <ButtonIcon
                      size="medium"
                      dataTestId="expand-row"
                      onClick={event =>
                        expandProps.onExpand(expandProps.record, event)
                      }
                      className={styles.iconWrapper}
                      variant="tertiary"
                    >
                      {expandProps.expanded ? (
                        <ChevronSmallUp className={styles.icon} />
                      ) : (
                        <ChevronSmallDown className={styles.icon} />
                      )}
                    </ButtonIcon>
                  ),
                  columnTitle: '',
                  onExpandedRowsChange: keys => setExpandedRowKeys([...keys]),
                  expandedRowKeys,
                  columnWidth: 50,
                  expandedRowRender: expandedRowProps => (
                    <div className={styles.expandedRow}>
                      {props.expandedRowRender?.(expandedRowProps)}
                    </div>
                  )
                }
              : undefined
          }
          onRow={(data: TData) => ({ onClick: () => props.onRowClick?.(data) })}
          components={components}
          rowKey="id"
          scroll={{
            x: props.tableWidth || '100%',
            y: props.tableHeight || '100%'
          }}
          emptyText={
            props.isLoading ? (
              <div className={styles.loader}>
                <Spinner />
              </div>
            ) : (
              'No data'
            )
          }
        />

        {props.pagination && props.pagination.count ? (
          <div className={styles.pagination}>
            <div>
              <Pagination
                id={props.id}
                page={props.pagination.page}
                count={props.pagination.count}
                pageSize={props.pagination.pageSize}
                onPageChange={props.onChangePage}
              />
            </div>
            <div className={styles.selectItems}>
              <Label
                id={'select-for-items-' + props.id}
                label={t('label.items-per-screen')}
                className={styles.selectItemsLabel}
              />
              <Select
                className={styles.selectItemsSelect}
                options={selectOptions}
                hideSearch
                id={'select-for-items-' + props.id}
                value={props.pagination.pageSize}
                onChange={handleOnPageSize}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

type ActionCellProps<TData> = {
  actions: Action<TData>[]
  record: TData
}

const ActionCell = <TData,>(props: ActionCellProps<TData>) => (
  <Dropdown
    sideOffset={-10}
    trigger={
      <ButtonIcon
        variant="tertiary"
        className={styles.iconWrapper}
        dataTestId="actions-button"
        size="small"
      >
        <MoreOutlined className={styles.icon} />
      </ButtonIcon>
    }
  >
    {props.actions?.map((action, index) => (
      <DropdownMenuItem
        onClick={() => action.onClick(props.record)}
        key={index}
      >
        {action.text}
      </DropdownMenuItem>
    ))}
  </Dropdown>
)

type SorterProps = {
  columnKey: React.Key
  sorting: Sorting | null
  children: React.ReactNode
}

const Sorter = (props: SorterProps) => (
  <div className={styles.headerSorted}>
    {props.children}
    <div className={styles.sorter}>
      <TriangleUp
        className={clsx(
          styles.sorterIcon,
          props.sorting?.key === props.columnKey &&
            props.sorting?.order === 'asc' &&
            styles.sorterIconActive
        )}
      />
      <TriangleDown
        className={clsx(
          styles.sorterIcon,
          props.sorting?.key === props.columnKey &&
            props.sorting?.order === 'desc' &&
            styles.sorterIconActive
        )}
      />
    </div>
  </div>
)

export default Table
