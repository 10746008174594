import ArrowRightIcon from '@/assets/icons/arrow-right.svg?react'
import { type SelectOption } from '@/components/Select/Select'
import { type FormFieldType } from '@/types/form-field-type'

import styles from './CoverTeacherField.module.scss'
import TeacherSelect from './TeacherSelect'
import { type TeacherAvailability } from '../types'

type CoverTeacherFieldProps = {
  placeholder: string
  teacher: {
    id: string
    name: string
  }
  inputProps: FormFieldType<string | undefined>
  teacherList: TeacherAvailability[]
  options: SelectOption<string>[]
  isOptionsLoading: boolean
  onClear: () => void
}

const CoverTeacherField = (props: CoverTeacherFieldProps) => {
  const options = props.options.filter(
    teacher => teacher.value !== props.teacher.id
  )

  return (
    <div className={styles.coverFieldWrapper}>
      <div className={styles.coverFieldContainer}>
        <div className={styles.currentTeacher}>{props.teacher.name}</div>

        <ArrowRightIcon className={styles.icon} />

        <TeacherSelect
          {...props.inputProps}
          teachersList={props.teacherList}
          isOptionsLoading={props.isOptionsLoading}
          options={options}
          placeholder={props.placeholder}
          onClear={props.onClear}
        />
      </div>
    </div>
  )
}

export default CoverTeacherField
