import { z } from 'zod'

export const formSchemaCoverTeacher = () =>
  z
    .object({
      teacher: z.string().optional().nullable(),
      coTeacher: z.string().optional().nullable()
    })
    .refine(data => data.teacher || data.coTeacher, {
      path: ['teacher'],
      message: ' '
    })
    .refine(data => data.teacher || data.coTeacher, {
      path: ['coTeacher'],
      message: ' '
    })

export type CoverTeacherFormSchema = z.infer<
  ReturnType<typeof formSchemaCoverTeacher>
>
