import { create } from 'zustand'

import { type Language } from '@/types/lanuage'

const LOCAL_TOKEN_KEY = 'token'

export type User = {
  id: string
  firstName: string
  lastName: string
  isTeacher: boolean
  isSuperAdmin: boolean
  appLanguage: Language
  profiles: string[]
  tutorClasses?: { id: string; name: string }[]
}

export type AuthStore = {
  isAuthenticated: boolean
  user: User | null
  isSuperAdmin: boolean
  logIn: (token: string) => void
  logOut: () => void
  setUser: (user: User) => void
}

const useAuthStore = create<AuthStore>(set => ({
  isAuthenticated: !!localStorage.getItem(LOCAL_TOKEN_KEY),
  user: null,
  isSuperAdmin: false,
  logIn: (token: string) =>
    set(() => {
      localStorage.setItem(LOCAL_TOKEN_KEY, token)
      return { isAuthenticated: true }
    }),
  setUser: (newUser: User) =>
    set(() => ({
      user: newUser,
      isSuperAdmin: !!newUser.isSuperAdmin
    })),
  logOut: () =>
    set(() => {
      localStorage.removeItem(LOCAL_TOKEN_KEY)
      return { user: null, isAuthenticated: false }
    })
}))

export default useAuthStore
